<template>
  <div class="flex">
      <div class="canvas_header">
        <h2>FIRMAR-1</h2>
        <v-btn class="secondary">GUARDAR</v-btn>
      </div>
      <div class="canvas_pad">
        <canvas ref="canvas" id='drawing-pad' width='700' height='300'/>          
        <div>
          <button @click='resetCanvas'>Reset Canvas</button>
          <button @click='saveImage'>Save Image</button>
          <button @click='replay'>Replay</button>
        </div>
        <!-- <img ref="img" src="" id='canvas-image'> -->
      </div>              
  </div>  
</template>

<script>
export default {
  
  data () {
    return {
      
      canvas: null,
      context: null,
      isDrawing: false,
      startX: 0,
      startY: 0,
      points: [],

      position: null,
      
    }
  },

  mounted(){
    var vm = this;
    vm.canvas = vm.$refs.canvas
    vm.context = vm.canvas.getContext("2d");
    vm.canvas.addEventListener('touchstart', vm.touchstart)
    vm.canvas.addEventListener('touchmove', vm.touchmove)
    vm.canvas.addEventListener('MSPointerDown', vm.drawpointerdown)
    vm.canvas.addEventListener('MSPointerMove', vm.drawpointermove)
    vm.canvas.addEventListener('mousedown', vm.mousedown);
    vm.canvas.addEventListener('mousemove', vm.mousemove)
    document.addEventListener('mouseup', vm.mouseup);
    //this.drawMouse();
    this.position=vm.canvas.position;
  },

  computed: {

  },

  methods: {

    /*drawMouse () {
      var vm = this;
      var clicked = 0;
      var position =vm.context.position();
      var rect = vm.canvas.getBoundingClientRect();
      
      var start = function(e) {
        clicked = 1;
        vm.context.beginPath();
        //let x = e.pageX-position.left;
        //let y = e.pageY-position.top;
        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;
        vm.context.moveTo(x,y);
      };
      var move = function(e) {
        if(clicked){
          //let x = e.pageX-position.left;
          //let y = e.pageY-position.top;
          let x = e.clientX - rect.left;
          let y = e.clientY - rect.top;
          vm.context.lineTo(x,y);
          vm.context.stroke();
        }
      };
      var stop = function(e) {
        clicked = 0;
      };
      vm.canvas.addEventListener('mousedown', start);
      vm.canvas.addEventListener('mousemove', move)
      document.addEventListener('mouseup', stop);      
    },*/

    mousedown(e) {
      var vm = this
      var rect = vm.canvas.getBoundingClientRect();
      var x = e.clientX - rect.left;
      var y = e.clientY - rect.top;
      
      vm.isDrawing = true;
      vm.startX = x;
      vm.startY = y;
      vm.points.push({ x: x, y: y });
    },

    mousemove(e){
      var vm = this
      var rect = vm.canvas.getBoundingClientRect();
      var x = e.clientX - rect.left;
      var y = e.clientY - rect.top;

      if (vm.isDrawing) {
        vm.context.beginPath();
        vm.context.moveTo(vm.startX, vm.startY);
        vm.context.lineTo(x, y);
        vm.context.lineWidth = 3;
        vm.context.lineCap = 'round';
        vm.context.strokeStyle = "rgba(0,0,0,1)";
        vm.context.stroke();
        
        vm.startX = x;
        vm.startY = y;  
        
        vm.points.push({ x: x, y: y });
      }
    },

    mouseup(e){
      console.log(e);
      var vm = this;
      vm.isDrawing = false;
      if (vm.points.length > 0) {
        localStorage['points'] = JSON.stringify(vm.points);
      }
    },

    touchstart(e) {
      console.log(e);
      var vm = this;
      e = e.originalEvent;
      let x = e.changedTouches[0].pageX - this.position.left;
      let y = e.changedTouches[0].pageY - this.position.top;
      vm.context.beginPath();
      vm.context.moveTo(x,y);
    },

    touchmove(e) {
      var vm = this;
      e.preventDefault();
      e = e.originalEvent;
      let x = e.changedTouches[0].pageX - this.position.left;
      let y = e.changedTouches[0].pageY - this.position.top;
      vm.context.lineTo(x,y);
      vm.context.stroke();
    },

    drawpointerdown(e) {
      var vm = this;
      e = e.originalEvent;
      let x = e.pageX - this.position.left;
      let y = e.pageY - this.position.top;
      vm.context.beginPath();
      vm.context.moveTo(x,y);
    },

    drawpointermove(e) {
      var vm = this;
      e.preventDefault();
      e = e.originalEvent;
      let x = e.pageX - this.position.left;
      let y = e.pageY - this.position.top;
      vm.context.lineTo(x,y);
      vm.context.stroke();
    },    

    resetCanvas() {
      var vm=this;
      //vm.context = vm.canvas.getContext("2d");
      vm.context.clearRect(0,0, vm.canvas.width, vm.canvas.height);

      //vm.canvas.width = this.canvas.width;
      vm.points.length = 0; // reset points array
    },

    saveImage() {
      var vm = this
      var dataURL = vm.canvas.toDataURL();
      var img = vm.$refs.img;
      img.src = dataURL;
    },

    replay() {
      /*var vm = this;
      vm.canvas.width = vm.canvas.width;
      
      // load localStorage
      if (vm.points.length === 0) {
        if (localStorage["points"] !== undefined) {
          vm.points = JSON.parse(localStorage["points"]);
        }
      }
      
      var point = 1;
      setInterval(function() {
        drawNextPoint(point);
        point += 1;
      },10);
      
      function drawNextPoint(index) { 

        if (index >= vm.points.length) return;
        var startX = vm.points[index-1].x;
        var startY = vm.points[index-1].y;
        
        var x = vm.points[index].x;
        var y = vm.points[index].y;
        
        vm.context.beginPath();
        vm.context.moveTo(startX, startY);
        vm.context.lineTo(x, y);
        vm.context.lineWidth = 3;
        vm.context.lineCap = 'round';
        vm.context.strokeStyle = "rgba(0,0,0,1)";        
	
        vm.context.stroke();
        
      }*/

    }    
  }
}

</script>

<style>
  
  canvas { border: 2px solid red; cursor:crosshair; }
  
</style>
